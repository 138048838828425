
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.flexible-links__title,
.flexible-links__list {
  @include mq(s) {
    padding: 0 col(1, 10);
  }

  @include mq(l) {
    padding: 0 col(2, 10);
  }
}

.flexible-links__list {
  margin: 0;
  list-style-type: none;

  @include mq($until: s) {
    padding-left: 0;
  }
}

.flexible-links__item {
  display: flex;

  .icon {
    flex-shrink: 0;
    margin-right: $spacing * 0.5;
    color: $c-mustard;
  }

  ::v-deep a {
    text-decoration-color: $c-gray-medium;
  }

  & + & {
    margin-top: $spacing * 0.5;
  }
}
